import React from "react";
import { FiPhoneCall } from "react-icons/fi";
import { TfiLocationPin } from "react-icons/tfi";
import { AiOutlineMail } from "react-icons/ai";
import "./Footer.css";

function Footer() {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row">
          {/* Column1 */}
          <div className="col">
            <div className="icon">
              <FiPhoneCall size="3rem" />
            </div>

            <div>
              <h5>Call Today</h5>
              <h4 className="list-unstyled">325-4646</h4>
            </div>
          </div>
          {/* Column2 */}
          <div className="col">
            <div className="icon">
              <TfiLocationPin size="3rem" />
            </div>
            <div>
              <h5>Section 56 Lot 13 Boio Street</h5>
              <h5>Boroko N.C.D., Papua New Guinea</h5>
            </div>
          </div>
          {/* Column3 */}
          <div className="col">
            <div className="icon">
              <AiOutlineMail size="3rem" />
            </div>
            <div>
              <h5>info@1avini.com</h5>
              <h5>https://www.1avini.com</h5>
            </div>
          </div>
        </div>
        <hr />
        <div className="copyright">
          <p>
            &copy;{new Date().getFullYear()} 1A VINI Finance Company Limited |
            All rights reserved
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
