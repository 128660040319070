import { lineHeight } from "@mui/system";
import { useEffect } from "react";
import { BsNewspaper } from "react-icons/bs";

/* We are a team specialized
            in money lending. Ever since 1996, 1A VINI has been offering
            personal loan services with easy turnaround process. With adequate
            experience of over 25 years of accumulating financial knowledge and
            experience, we have mastered the personal loan services and and are
            ready to provide tailored solution to meet your financial needs. */

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="about">
        <h1>About us</h1>
        <div className="team-desc">
          <h2>Professional Staff Committed to Excellence</h2>
          <br></br>
          <h4 style={{ lineHeight: 1.6 }}>
            1A VINI is a trusted money lending company that provides fast and
            reliable financial solutions tailored to meet the unique needs of
            our customers. Our experienced team of professionals is committed to
            delivering exceptional customer service and innovative lending
            options that help you achieve your financial goals.
            <br />
            <br />
            We offer a range of loan options to suit your specific requirements,
            whether you need a short-term loan to cover unexpected expenses or a
            loan to finance a major purchase. At 1A VINI, we prioritize
            transparency, honesty, and accountability in all our dealings with
            customers. Our financial prudence and adherence to industry
            regulations and standards ensure that our customers can have peace
            of mind knowing that they are working with a reputable and
            responsible money lending company.
          </h4>
        </div>
      </div>

      <div className="values">
        <div>
          <h1>Our Work Is Heavily Shaped by Our Core Values</h1>
        </div>
        <div className="value-list">
          <div className="value value1">
            <p className="value-type">Integrity</p>
            <p className="value-desc">
              At 1A VINI, we believe that trust is essential in the money
              lending industry. We conduct all our business dealings with the
              utmost honesty and transparency, and our team members adhere to
              strict ethical standards to ensure that our customers can rely on
              us to do the right thing.
            </p>
          </div>
          <div className="value value2">
            <p className="value-type">Customer-centricity</p>
            <p className="value-desc">
              Our customers are at the heart of everything we do. We understand
              that every customer has unique financial needs, which is why we
              offer personalized solutions and exceptional service to ensure
              that they can achieve their financial goals.
            </p>
          </div>
          <div className="value value3">
            <p className="value-type">Accountability</p>
            <p className="value-desc">
              We take pride in being accountable for our actions and delivering
              on our promises. Our team members are dedicated to providing fast
              and reliable financial solutions that meet our customers' needs,
              while also being transparent and accountable in all our business
              dealings.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
