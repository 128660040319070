import { useEffect } from "react";
import { Link } from "react-router-dom";

function Forms() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="forms">
        <h1 style={{ flex: 2 }}>Forms</h1>

        <h2 className="m-5">
          To help expidite your approval process, please fill out the forms and
          prepare the required documents below:
        </h2>
        <ul>
          <li>Latest Original Payslip</li>
          <li>Payment Variation Advice (PVA)</li>

          <li>Copy of ID card</li>
          <li>Employment Status Confirmation Letter</li>

          <li>
            {" "}
            Download and fill:&nbsp;
            <Link
              to="/files/Loan Application Form.pdf"
              target="_blank"
              download
            >
              Loan Application Form and Agreements
            </Link>
          </li>
          <li>
            {" "}
            Download and fill:&nbsp;
            <Link
              to="/files/STATUTORY DECLARATION .pdf"
              target="_blank"
              download
            >
              Statutory Declaration
            </Link>
          </li>
        </ul>
        <div className="m-5 d-flex justify-content-between align-items-center flex-column">
          <h1 className="text-success">Easy as that !!</h1>
          <h1 className=" text-primary">and MORE...</h1>
          <h1 className="p-3 text-center border rounded-4 border-success border-3 text-danger ">
            Successful Applicatants will be entitled to up to 5% of loan amount
            rebate / deduction.
          </h1>
        </div>
      </div>
    </>
  );
}

export default Forms;
