import React from "react";
import { useState, useEffect } from "react";
import { Slider } from "@mui/material";
import "./LoanCalculator.css";

function LoanCalculator() {
  const [loanAmount, setLoanAmount] = useState(2000);
  const [loanSpan, setLoanSpan] = useState(10);
  const [repayment, setRepayment] = useState(282);

  useEffect(() => {
    calculateRepayment();
  }, [loanAmount, loanSpan]);

  const rateSchedule = [
    0.33, 0.33, 0.33, 0.33, 0.33, 0.33, 0.33, 0.34, 0.35, 0.36, 0.37, 0.39,
    0.41, 0.43, 0.45, 0.45, 0.45, 0.45, 0.45, 0.45, 0.45, 0.45, 0.45, 0.45,
    0.45, 0.45, 0.45, 0.45, 0.45,
  ];

  const handleLoanAmountChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    if (typeof newValue === "number") {
      setLoanAmount(newValue.toFixed(0));
    }
  };

  const handleLoanSpanChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setLoanSpan(newValue.toFixed(0));
    }
  };

  const calculateRepayment = () => {
    let decimalFormat = rateSchedule[loanSpan] + 1;
    let totalOwed = decimalFormat * loanAmount;
    let repayment = totalOwed / loanSpan;

    console.log(
      loanAmount,
      rateSchedule[loanSpan],
      totalOwed,
      loanSpan,
      repayment
    );

    setRepayment(repayment);
  };

  return (
    <div>
      <div className="loan-calculator">
        <div className="title">Loan Calculator</div>
        <h4>I want to borrow K{loanAmount}</h4>
        <Slider
          aria-label="Loan Amount"
          defaultValue={600}
          getAriaValueText={(value) => `K${value}`}
          step={100}
          min={300}
          max={20000}
          onChange={handleLoanAmountChange}
        />
        <h4>Over {loanSpan} fortnights</h4>
        <Slider
          aria-label="Loan Span"
          defaultValue={10}
          getAriaValueText={(value) => `K${value}`}
          step={1}
          marks
          min={4}
          max={26}
          onChange={handleLoanSpanChange}
        />
        <div>
          <p className="repayment">
            {" "}
            K{repayment.toFixed(2)} repayment every fortnight.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoanCalculator;
