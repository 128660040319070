import React, { useState } from "react";
import "./Membership.css"; // Import your CSS file for styling

const MembershipPage = () => {
  const [selectedMembership, setSelectedMembership] = useState("bronze");

  const membershipLevels = [
    {
      id: "gold",
      name: "Gold",
      fee: 50,
      benefits: {
        clubhouse: 100,
        hotel: 50,
        airTickets: 20,
        cosmetics: 15,
      },
      description:
        "Experience the pinnacle of luxury with our Gold Membership. Enjoy exclusive access to our opulent clubhouse, receive a lavish 50% discount on hotel bookings, and jet-set with a remarkable 20% off on air tickets. Pamper yourself further with a delightful 15% discount on exquisite cosmetic products.",
    },
    {
      id: "silver",
      name: "Silver",
      fee: 30,
      benefits: {
        clubhouse: 50,
        hotel: 30,
        airTickets: 15,
        cosmetics: 10,
      },
      description:
        "Elevate your lifestyle with the Silver Membership. Access our elegant clubhouse with a 50% discount, book hotels at a generous 30% off, and travel in style with a 15% discount on air tickets. Enhance your beauty regimen with a fabulous 10% discount on premium cosmetic products.",
    },
    {
      id: "bronze",
      name: "Bronze",
      fee: 10,
      benefits: {
        clubhouse: 20,
        hotel: 10,
        airTickets: 5,
        cosmetics: 5,
      },
      description:
        "Discover the charm of the Bronze Membership. Enjoy a 20% discount for clubhouse access, relax and save with a 10% discount on hotel bookings, and embark on new adventures with a 5% discount on air tickets. Embrace your natural beauty with a wonderful 5% discount on cosmetic products.",
    },
  ];

  const handleMembershipChange = (membershipId) => {
    setSelectedMembership(membershipId);
  };

  return (
    <div className="membership-container">
      <h1>Exclusive Membership Club</h1>
      <p className="description">
        Elevate your experience with our premium membership levels. Unlock a
        world of extraordinary benefits and savings that cater to your
        lifestyle.
      </p>
      <div className="membership-options">
        {membershipLevels.map((level) => (
          <div
            key={level.id}
            className={`membership-option ${
              selectedMembership === level.id ? "selected" : ""
            }`}
            onClick={() => handleMembershipChange(level.id)}
          >
            <div className={`membership-card ${level.id}`}>
              <h2>{level.name} Membership</h2>
              <p className="fee">Membership Fee: ${level.fee}</p>
              <p className="membership-description">{level.description}</p>
              <div className="benefits">
                <p>Clubhouse: {level.benefits.clubhouse}% Off</p>
                <p>Hotel Booking: {level.benefits.hotel}% Off</p>
                <p>Air Tickets: {level.benefits.airTickets}% Off</p>
                <p>Cosmetic Products: {level.benefits.cosmetics}% Off</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default MembershipPage;
