import { useEffect } from "react";
import { AiOutlineMail } from "react-icons/ai";
import ContactForm from "../components/ContactForm";
import { FiPhoneCall } from "react-icons/fi";

function Contact() {
  const gKey = "AIzaSyDjHstKTS_9mjv9mDYVXMyLWMNXd43ZVWE";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="contact pt-5">
        <h1>Contact us</h1>
        <div className="contact-info">
          <div className="contact-form">
            <ContactForm />
          </div>
          <div className="container map">
            <h2>Meet us</h2>
            <h4>
              Section 56 Lot 13 Boio Street, Boroko N.C.D., Papua New Guinea
            </h4>
            <div className="d-flex column align-items-center ">
              <FiPhoneCall size="2rem" style={{ paddingRight: "5px" }} />
              <h4>325-4646</h4>
            </div>
            <iframe
              title="1A Vini Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3935.4469388664206!2d147.2054716496152!3d-9.46979740186009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x690236c41581917d%3A0xde73a70fb78239b!2sBoio%20St%2C%20Port%20Moresby%2C%20Papua%20New%20Guinea!5e0!3m2!1sen!2shk!4v1678011287834!5m2!1sen!2shk"
              width="400"
              height="300"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
