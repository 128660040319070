// PromoPage.js
import React from "react";
import "./PromoPage.css"; // You can style your page using CSS
import { Button } from "@mui/material";

function PromoPage() {
  return (
    <div className="promo-page">
      <header className="promo-header">
        <h1>1AVINI Finance Benefits</h1>
        <p style={{ fontSize: 18, color: "darkgray" }}>
          We stand beside you, just like kin.
        </p>
      </header>
      <section className="promo-benefits">
        <h3 style={{ margin: 18 }}>
          If you are a honorable past or existing borrower, you have the chance
          to become our premium member and enjoy exclusive privileges:
        </h3>
        <div className="benefits-container">
          <div className="benefit">
            <h3>Overseas Work Opportunities</h3>
            <p>
              Your youths have the opportunity to work overseas. We assist in
              securing job placements, applying for visas/permits, and providing
              essential skill training.
            </p>
          </div>
          <div className="benefit">
            <h3>Discounted Travel</h3>
            <p>
              Explore the world with discounted hotel and airline ticket
              bookings for your international journeys.
            </p>
          </div>
          <div className="benefit">
            <h3>Discounted Cosmetic Products</h3>
            <p>
              Enhance your beauty and well-being with exclusive discounts on a
              range of cosmetic products.
            </p>
          </div>
          <div className="benefit">
            <h3>Club House Reservation</h3>
            <p>
              Host parties or meetings with style through discounted club house
              reservations.
            </p>
          </div>
        </div>
      </section>
      <section className="d-flex justify-content-center mb-5">
        <p className="d-flex flex-row align-items-center  h3 me-4 gap-2">
          <div>To become a premium member and unlock exclusive perks</div>

          <Button
            variant="contained"
            color="primary"
            size="large"
            href="/signup"
            style={{ fontSize: 14 }}
          >
            Apply Now
          </Button>
        </p>
      </section>
    </div>
  );
}

export default PromoPage;
