import React from "react";
import emailjs from "emailjs-com";
import "./ContactForm.css";
import { Button } from "@mui/material";

const ContactForm = () => {
  const [formStatus, setFormStatus] = React.useState("Send");
  const onSubmit = (e) => {
    e.preventDefault();
    setFormStatus("Submitting...");
    const { name, phone, email, message } = e.target.elements;
    let templateParams = {
      name: name.value,
      phone: phone.value,
      email: email.value,
      message: message.value,
    };

    emailjs
      .send(
        "service_pkexae1",
        "template_onrr6tn",
        templateParams,
        "2GYQT7UPATesnGg0X"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setFormStatus("Submit");
        },
        (err) => {
          console.log("FAILED...", err);
          setFormStatus("Submit");
        }
      );

    // console.log(templateParams);
    // setFormStatus("Submit");
  };
  return (
    <div className="container mt-5">
      <h2 className="mb-3">Drop us a note</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label className="form-label" htmlFor="name">
            Name
          </label>
          <input className="form-control" type="text" id="name" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="phone">
            Phone
          </label>
          <input className="form-control" type="phone" id="phone" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Email
          </label>
          <input className="form-control" type="email" id="email" required />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="message">
            Message
          </label>
          <textarea className="form-control h-150" id="message" required />
        </div>
        <Button variant="contained" type="submit">
          {formStatus}
        </Button>
      </form>
    </div>
  );
};
export default ContactForm;
