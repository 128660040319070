import { useEffect } from "react";
import LoanCalculator from "../components/LoanCalculator";
import { Divider, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import FadeInSection from "../components/FadeInSection";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="home">
        <div className="tags">
          <p className="tag1">Best Solution To Your Financial Needs.</p>{" "}
          <p className="tag2">
            1A VINI Finance is a small scale lender in Papua New Guinea. We have
            served a large number of customers in the past 25 years. We will
            continue to strive to become your Best Money Lender.
          </p>
          <p className="tag2">
            Looking for a reliable money lending company that can provide you
            with fast and flexible financial solutions? Look no further than 1A
            VINI! With a strong reputation for exceptional customer service and
            innovative lending options, we are committed to helping you achieve
            your financial goals. Contact us today to learn more!
          </p>
        </div>
        <div className="calculator">
          <LoanCalculator />
        </div>
      </div>

      <Box sx={{ border: "1px solid grey" }}></Box>

      <div className="services">
        <div className="loan loan1 ">
          <FadeInSection>
            <p className="loan-type fade-in-up" data-mdb-animation="fade-in-up">
              Personal Loan
            </p>
            <p className="loan-desc fade-in-up">
              Our personal loans at 1A VINI are the perfect solution! With
              competitive interest rates, fast approval process, our loans are
              designed to meet your unique financial needs. Whether you're
              planning a home renovation, paying off medical bills, or just need
              some extra cash, our personal loans are the perfect way to get the
              funds you need quickly and easily.
            </p>
            <br />

            <Button
              className="apply-button align-self-center"
              variant="contained"
              component={Link}
              to="/forms"
              color="primary"
            >
              Apply Now and claim your gift!
            </Button>
          </FadeInSection>
        </div>
        <div className="loan loan2">
          <FadeInSection>
            <p className="loan-type">Instalment Loan</p>
            <p className="loan-desc">
              Our instalment loans are repaid over a fixed period in equal
              installments, and can be used for various purposes such as home
              improvements, appliance purchases, or debt consolidation. With
              competitive interest rates, and fast approval process, our
              instalment loans are the perfect way to get the funds you need
              quickly and easily.
            </p>
            <br />

            <Button
              className="apply-button"
              variant="contained"
              component={Link}
              to="/forms"
              size="large"
              color="primary"
            >
              Apply Now and claim your gift!
            </Button>
          </FadeInSection>
        </div>
        <div className="loan loan3">
          <FadeInSection>
            <p className="loan-type">Education Loan</p>
            <p className="loan-desc">
              At 1A VINI, we understand that pursuing higher education can be
              expensive. That's why we offer affordable education loans to help
              you achieve your academic dreams. Our loans come with competitive
              interest rates, prompt approval process, and no hidden fees,
              making them the perfect solution for students looking to finance
              their education.
            </p>
            <br />

            <Button
              className="apply-button"
              variant="contained"
              component={Link}
              to="/forms"
              size="large"
              color="primary"
            >
              Apply Now and claim your gift!
            </Button>
          </FadeInSection>
        </div>
      </div>
      <Divider light />
    </>
  );
}

export default Home;
