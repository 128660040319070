import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Navbar from "./components/Navbar";
import "./App.css";
import Home from "./routes/Home";
import About from "./routes/About";
import Contact from "./routes/Contact";
import Forms from "./routes/Forms";
import ErrorPage from "./routes/ErrorPage";
import Footer from "./components/Footer";
import MembershipPage from "./routes/Membership";
import PromoPage from "./routes/PromoPage";
import SignUpPage from "./routes/SignUpPage";
import ReactGA from "react-ga";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// Initialize React Ga with your tracking ID
ReactGA.initialize("G-1N1TXBS0J1");
const AppLayout = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
};

const router = createBrowserRouter([
  {
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "benefits",
        element: <PromoPage />,
      },
      {
        path: "membership",
        element: <MembershipPage />,
      },
      {
        path: "signup",
        element: <SignUpPage />,
      },
      {
        path: "forms",
        element: <Forms />,
      },
      {
        path: "about",
        element: <About />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
